<template>
  <div class="content-wrapper">
    <Breadcrumb style="margin-bottom: 14px;">
      <BreadcrumbItem>{{ $t('cha-xun-pei-zhi') }}</BreadcrumbItem>
    </Breadcrumb>
    <DataSourceHeader :handleSearch="getDataSourceList" :searchKey="searchKey" :supportAdd="false"
                      :handleShowAddDataSource="handleShowAddDataSource" :handleChangeSearchType="handleChangeSearchType"
                      :refreshLoading="refreshLoading"></DataSourceHeader>
    <div class="data-source-container">
      <div style="margin-top: 16px">
        <Table size="small" border :columns="getColumns" :data="showData">
          <template slot-scope="{ row }" slot="instanceId">
            <div>
              <div style="position:relative;">
                <span>{{ row.instanceId }}</span>
                <Tooltip placement="right" class="alarm-icon" transfer :content="$t('cun-zai-yi-chang-de-hou-tai-ren-wu-qing-dian-ji-chu-li')"
                         v-if="row.consoleTaskState==='FAILED'">
                <span style="display: inline-block" @click="handleGoConsoleJob(row)"><i
                  class="iconfont iconyibuforce"></i></span>
                </Tooltip>
                <Poptip placement="right" width="680" class="show-datasource-info-icon" transfer>
                  <i @click="getDataSourceDetail(row)" class="iconfont iconinfo"></i>
                  <div slot="content" style="line-height: 24px">
                    <DataSourceInDetail :dataSourceDetail="sourceDetail"></DataSourceInDetail>
                  </div>
                </Poptip>
              </div>
              <div style="color:#cccccc">{{ row.instanceDesc }}</div>
            </div>
          </template>
          <template slot-scope="{ row }" slot="action">
            {{ '' }}
<!--            <a-button-->
<!--              size="small" type="link" :disabled="!ifSupported(row)"-->
<!--              @click.native="handleActions( {key:'enableQuery'}, row)"-->
<!--              v-if="!isDesktop && !row.enableQuery">-->
<!--              {{ $t('qi-yong-cha-xun-gong-neng') }}-->
<!--            </a-button>-->
<!--            <a-button-->
<!--              size="small" type="link"-->
<!--              @click.native="handleActions( {key:'disableQuery'}, row)"-->
<!--              v-if="!isDesktop && row.enableQuery">-->
<!--              {{ $t('ting-yong-cha-xun-gong-neng') }}-->
<!--            </a-button>-->
<!--            <a-button-->
<!--              size="small" type="link"-->
<!--              @click.native="handleActions( {key:'ticket'}, row)"-->
<!--              v-if="!isDesktop && row.enableQuery">-->
<!--              {{ $t('gong-dan-shen-pi-liu-cheng') }}-->
<!--            </a-button>-->
<!--            <a-button disabled=""-->
<!--              size="small" type="link"-->
<!--              v-if="!isDesktop && !row.enableQuery">-->
<!--              {{ $t('gong-dan-shen-pi-liu-cheng') }}-->
<!--            </a-button>-->
            <a-button
              size="small" type="link"
              @click.native="handleActions( {key:'go_params'}, row)"
              v-if="row.enableQuery">
              {{ $t('xiu-gai-can-shu-pei-zhi') }}
            </a-button>
            <a-button disabled=""
              size="small" type="link"
              v-if="!row.enableQuery">
              {{ $t('xiu-gai-can-shu-pei-zhi') }}
            </a-button>
          </template>
          <template slot-scope="{ row }" slot="host">
            <div class="host-type">
              <p v-if="row.privateHost"><span class="host-type-label">{{ $t('nei') }}</span>{{ row.privateHost }}
              </p>
              <p v-if="row.publicHost" style="margin-top: 3px"><span
                class="host-type-label">{{ $t('wai') }}</span>{{ row.publicHost }}</p>
            </div>
          </template>
          <template slot-scope="{ row }" slot="dataSourceType">
            <div>
              <Tooltip style="margin-left: 10px;font-size: 24px;cursor: pointer" placement="right"
                       class="dataSource-icon" transfer
                       :content="`${row.deployType==='ALIBABA_CLOUD_HOSTED'?Mapping.aliyunType[row.dataSourceType]:row.dataSourceType}`">
                <DataSourceIcon :type="row.dataSourceType"
                                :instanceType="row.deployType"></DataSourceIcon>
              </Tooltip>
            </div>
          </template>
          <template slot-scope="{ row }" slot="deployType">
            <div>
              <span>{{ DATASOURCE_DEPLOY_TYPE_I18N[row.deployType] }}</span>
            </div>
          </template>
          <template slot-scope="{ row }" slot="enableQuery">
<!--            <div :class="row.enableQuery? 'active': 'inactive'">-->
<!--              {{ row.enableQuery? $t('yi-kai-qi') : $t('wei-kai-qi') }}-->
<!--            </div>-->
            <div>
              <Tooltip placement="right" transfer :content="$t('zan-wei-kai-fang-gai-shu-ju-yuan')"
                       v-if="!ifSupported(row)">
                <i-switch disabled v-if="!ifSupported(row)" true-color="#52C41A" v-model="row.enableQuery"></i-switch>
              </Tooltip>
              <i-switch v-if="ifSupported(row)" @on-change="handleEnableQuery(row)" true-color="#52C41A" v-model="row.enableQuery"></i-switch>
            </div>
          </template>
        </Table>
      </div>
    </div>
    <div class="page-footer-container">
      <div class="page-footer-paging">
        <Page :total="total" show-total show-elevator @on-change="handlePageChange" show-sizer
              :page-size="size"
              @on-page-size-change="handlePageSizeChange"/>
      </div>
    </div>
    <!--    <Page class="page-container" :total="total" show-total show-elevator @on-change="handlePageChange" show-sizer-->
    <!--          :page-size="size"-->
    <!--          @on-page-size-change="handlePageSizeChange"/>-->
    <StToken ref="stToken"></StToken>
    <AliyunAKSK ref="aliyunAKSK"></AliyunAKSK>
    <Modal
      v-model="showEditDesc"
      :title="$t('xiu-gai-shu-ju-yuan-miao-shu')"
      footer-hide
      width="400px"
    >
      <div>
        <p>
          {{ $t('xiu-gai-id-wei-selectedrowinstanceid-de-shu-ju-yuan-de-miao-shu-wei', [selectedRow.instanceId]) }}
        </p>
        <Input v-model="instanceDesc" style="width: 280px;margin-top: 20px"/>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEditDesc">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showEditAccount"
      :title="$t('xiu-gai-zhang-hao')"
      footer-hide
      width="530px"
    >
      <div>
        <Form label-position="right" :label-width="80" style="margin-top: 10px" :model="accountInfo"
              :rules="accountInfoValidate"
              ref="account-info-form">
          <FormItem :label="$t('ren-zheng-fang-shi')" key="securityType">
            <Select v-model="accountInfo.securityType" style="width: 280px">
              <Option v-for="security in securitySetting" :value="security.securityType"
                      :key="security.securityType">{{ security.securityTypeI18nName }}
              </Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('zhang-hao')" prop="account" key="account"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needUserName">
            <Input v-model="accountInfo.account" style="width: 280px"></Input>
          </FormItem>
          <FormItem :label="$t('mi-ma')" prop="password" key="password"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needPassword">
            <Input type="password" :placeholder="$t('mo-ren-bu-zhan-shi-dang-qian-mi-ma-qing-shu-ru-xin-mi-ma')" password
                   autocomplete="new-password"
                   v-model="accountInfo.password" style="width: 280px"></Input>
          </FormItem>
          <FormItem :label="$t('ssl-pei-zhi-wen-jian')" prop="securityFile" key="securityFile"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needTlsFile">
            <input @change="handleFileChange" type="file" name="uploadfile" id="uploadfile1"/>
            <span style="margin-left: 10px;color: rgb(128, 134, 149)"></span>
          </FormItem>
          <FormItem :label="$t('ca-zheng-shu')" prop="caFile" key="caFile"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needCaFile">
            <input @change="handleCaFileChange" type="file" name="uploadfile" id="uploadfile1"/>
            <span style="margin-left: 10px;color: rgb(128, 134, 149)"></span>
          </FormItem>
          <FormItem :label="$t('kerberos-pei-zhi-wen-jian')" prop="securityFile" key="securityFile2"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needKrb5File">
            <input @change="handleFileChange" type="file" name="uploadfile" id="uploadfile"/>
          </FormItem>
          <FormItem :label="$t('keytab-wen-jian')" prop="secretFile" key="secretFile"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needKeyTabFile">
            <input @change="handleKeyTabFileChange" type="file" name="uploadKeytabFile"
                   id="uploadKeytabFile"/>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer">
          <pd-button type="primary" @click="confirmEditAccount">{{ $t('bao-cun') }}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showUpdateAKSK"
      :title="$t('xiu-gaiali-yun-aksk')"
      footer-hide
      width="450px"
    >
      <div>
        <p style="color: #888888;margin-left: 8px;">{{ $t('gaiali-yun-assk-jin-yong-yu-shu-ju-tong-bu-shi-la-qu-bei-fen-ri-zhi-wen-jian-wei-bi-tian-xiang') }}</p>
        <Form label-position="right" :label-width="130" style="margin-top: 10px"
              ref="account-aksk-form" :model="accountAkSk" :rules="accountAkSkValidate">
          <FormItem label="AccessKey ID" prop="accessKey">
            <Input v-model="accountAkSk.accessKey" style="width: 280px" autocomplete="new-password"></Input>
          </FormItem>
          <FormItem label="AccessKey Secret" prop="secretKey">
            <Input type="password" password v-model="accountAkSk.secretKey"
                   style="width: 280px" autocomplete="new-password"></Input>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer">
          <pd-button type="primary" @click="confirmUpdateAKSK">{{ $t('bao-cun') }}</pd-button>
          <!--                    <pd-button type="error" ghost @click="handleDeleteAccount">删除</pd-button>-->
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <AddWhiteList :showAddWhiteList="showAddWhiteList" :selectedRow="selectedRow" :handleCancelEdit="handleCancelEdit"></AddWhiteList>
    <!--    <Modal-->
    <!--      v-model="showAddWhiteList"-->
    <!--      :title="$t('tian-jia-bai-ming-dan')"-->
    <!--      footer-hide-->
    <!--      width="430px"-->
    <!--    >-->
    <!--      <div>-->
    <!--        <Alert style="margin-bottom: 20px" type="warning" show-icon>{{ $t('she-ding-fang-wen-ji-qi-tian-jia-ji-qi-dao-shu-ju-yuan-bai-ming-dan') }}</Alert>-->
    <!--        <Form label-position="right" :label-width="100" style="margin-top: 10px">-->
    <!--          <FormItem :label="$t('xuan-ze-chan-pin-ji-qun')">-->
    <!--            <Select @on-change="handleGetWorkerCluster">-->
    <!--              <Option v-for="productCluster in productClusterList" :value="productCluster.clusterCode" :key="productCluster.clusterCode">{{productCluster.clusterDesc}}</Option>-->
    <!--            </Select>-->
    <!--          </FormItem>-->
    <!--          <FormItem :label="$t('xuan-ze-ji-qi')">-->
    <!--            <div class="add-white-list-container">-->
    <!--              <SelectCluster :row="selectedRow" ref="selectCluster" :workerClusterList="workerClusterList"-->
    <!--                             :handleChangeType="handleChangeType"></SelectCluster>-->
    <!--            </div>-->
    <!--          </FormItem>-->
    <!--        </Form>-->
    <!--        <div slot="footer" class="modal-footer">-->
    <!--          <pd-button type="primary" @click="confirmAddWhiteList">{{ $t('que-ding') }}</pd-button>-->
    <!--          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </Modal>-->
    <!--    <Modal v-model="showAddWhiteListProgress" :title="$t('tian-jia-bai-ming-dan')" footer-hide>-->
    <!--      <div style="padding: 20px">-->
    <!--        <Progress :percent="currentPercentage" :stroke-width="20"-->
    <!--                  :status="currentAddWhiteListStatus==='SUCCESS'?'success':(currentAddWhiteListStatus==='FAILED'?'wrong':'active')"-->
    <!--                  text-inside/>-->
    <!--        <p style="padding-left:10px;text-align: center;margin-top: 16px;font-weight:500">-->
    <!--          {{-->
    <!--            currentAddWhiteListStatus === 'SUCCESS' ? $t('tian-jia-cheng-gong') : currentAddWhiteListStatus === 'FAILED' ? $t('tian-jia-shi-bai') : $t('tian-jia-bai-ming-dan-zhong')-->
    <!--          }}</p>-->
    <!--      </div>-->
    <!--    </Modal>-->
    <Modal
      v-model="showEditPublicHost"
      :title="$t('xiu-gai-shu-ju-yuan-wai-wang-di-zhi')"
      footer-hide
      width="620px"
      :closable="false"
    >
      <div>
        <p>
          {{ showUpdateHttpHost ? $t('xiu-gai-id-wei-selectedrowinstanceid-de-shu-ju-yuan-de-wai-wang-client-di-zhi-wei', [selectedRow.instanceId]) : $t('xiu-gai-id-wei-selectedrowinstanceid-de-shu-ju-yuan-de-wai-wang-di-zhi-wei', [selectedRow.instanceId]) }}
        </p>
        <div style="margin-top: 20px">
          <Input v-model="publicHost" :placeholder="$t('ip-huo-yu-ming-duan-kou')" style="width: 460px"/>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEditPublicHost">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showEditPrivateHost"
      :title="$t('xiu-gai-shu-ju-yuan-nei-wang-di-zhi')"
      footer-hide
      width="620px"
      :closable="false"
    >
      <div>
        <p>
          {{ showUpdateHttpHost ? $t('xiu-gai-id-wei-selectedrowinstanceid-de-shu-ju-yuan-de-nei-wang-client-di-zhi-wei', [selectedRow.instanceId]) : $t('xiu-gai-id-wei-selectedrowinstanceid-de-shu-ju-yuan-de-nei-wang-di-zhi-wei', [selectedRow.instanceId]) }}
        </p>
        <div style="margin-top: 20px">
          <Input v-model="privateHost" style="width: 460px" :placeholder="$t('ip-huo-yu-ming-duan-kou')"/>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEditPrivateHost">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal :title="$t('pei-zhi')" v-model="showKvConfigsModal" :width="1000" footer-hide>
      <config-params-edit :ds-kv-configs="dsKvConfigs"/>
      <div class="footer" style="margin-top: 10px;text-align: center;">
        <Button @click="handleCloseModal" style="margin-right: 20px;">{{ $t('qu-xiao') }}</Button>
        <Button type="primary" @click="handleUpsertConfigs">{{ $t('xiu-gai') }}</Button>
      </div>
    </Modal>
    <test-connection-modal :visible="showTestConnectionModal" :datasource="selectedRow" :handle-close-modal="handleCloseTestConnectionModal" type="dataSourceList"/>
    <EnableQueryModal :visible="showEnableQueryModal" :datasource="selectedDatasource" :handle-close-modal="handleClose" :get-ds-list="getDataSourceList" type="dataSourceList"></EnableQueryModal>
    <ticket-modal :visible="showTicketModal" :handle-close-modal="hideTicketModal"
                  :ds="selectedDatasource"
                  v-if="showTicketModal"/>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import {
  queryById, queryDsConfig,
  upsertDsConfig
} from '@/services/cc/api/rdpDs';
import { dsSecurityOption } from '@/services/cc/api/rdpConstant';
import _ from 'lodash';
import { addClusterWhiteList } from '@/services/cc/api/ailyunRds';
import { queryConsoleJob } from '@/services/cc/api/consoleJob';
import DataSourceIcon from '@/components/function/DataSourceIcon';
import DataSourceHeader from '@/components/function/addDataSource/DataSourceHeader';
import DataSourceInDetail from '@/components/function/addDataSource/DataSourceInDetail';
// import SelectCluster from '@/components/function/addDataSource/SelectCluster';
import StToken from '@/components/function/ApplyStToken';
import AliyunAKSK from '@/components/function/ApplyAKSK';
import ConfigParamsEdit from '@/views/system/ConfigParamsEdit';
import { updateAccountAndPassword } from '@/services/cc/api/rdpUpload';
import { listClusterByCondition } from '@/services/cc/api/cluster';
import { isOracle } from '@/utils';
import fecha from 'fecha';
import {
  DATASOURCE_DEPLOY_TYPE,
  DATASOURCE_DEPLOY_TYPE_I18N,
  SECOND_CONFIRM_EVENT_LIST
} from '@/const';
import AddWhiteList from '@/components/modal/AddWhiteList';
import store from '@/store/index';
import TestConnectionModal from '@/components/function/addDataSource/TestConnectionModal';
import { Modal } from 'ant-design-vue';
import { ACTIONS_TYPE } from '@/store/actions';
import EnableQueryModal from '@/components/function/addDataSource/EnableQueryModal';
import TicketModal from '@/views/system/datasource/components/TicketModal';
import Mapping from '../../util';

export default {
  name: 'DataSource',
  components: {
    TicketModal,
    EnableQueryModal,
    ConfigParamsEdit,
    TestConnectionModal,
    DataSourceIcon,
    DataSourceHeader,
    DataSourceInDetail,
    StToken,
    AliyunAKSK,
    AddWhiteList
  },
  mounted() {
    // this.getDataSourceList();
    this.listEnv();
  },
  data() {
    return {
      showKvConfigsModal: false,
      dsKvConfigs: [],
      showTestConnectionModal: false,
      securitySetting: [],
      securitySettingObj: {},
      store,
      publicHost: '',
      privateHost: '',
      publicHttpHost: '',
      privateHttpHost: '',
      sid: '',
      publicSid: '',
      showEditPublicHost: false,
      showEditPrivateHost: false,
      currentAddWhiteListStatus: '',
      currentPercentage: 0,
      showAddWhiteListProgress: false,
      showAddWhiteList: false,
      showEditAccount: false,
      showUpdateAKSK: false,
      showEnableQueryModal: false,
      showTicketModal: false,
      selectedDatasource: {},
      accountInfo: {
        account: '',
        password: '',
        securityType: '',
        securityFile: '',
        caFile: '',
        secretFile: ''
      },
      accountAkSk: {
        accessKey: '',
        secretKey: ''
      },
      accountAkSkValidate: {
        accessKey: [
          {
            required: true,
            message: this.$t('accesskey-bu-neng-wei-kong')
          }
        ],
        secretKey: [
          {
            required: true,
            message: this.$t('secretkey-bu-neng-wei-kong')
          }
        ]
      },
      accountInfoValidate: {
        account: [
          {
            required: true,
            message: this.$t('zhang-hao-bu-neng-wei-kong')
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('mi-ma-bu-neng-wei-kong')
          }
        ],
        securityFile: [
          {
            required: true,
            message: this.$t('ssl-pei-zhi-wen-jian-bu-neng-wei-kong')
          }
        ],
        caFile: [
          {
            required: true,
            message: this.$t('ca-zheng-shu-bu-neng-wei-kong')
          }
        ],
        secretFile: [
          {
            required: true,
            message: this.$t('keytab-wen-jian-bu-neng-wei-kong')
          }
        ]
      },
      sourceDetail: {},
      Mapping,
      regionFilters: [],
      envData: [],
      showEditDesc: false,
      instanceDesc: '',
      selectedRow: {},
      refreshLoading: false,
      showAddDataSource: false,
      dataSourceTypes: [],
      workerClusterList: [],
      page: 1,
      size: 20,
      total: 0,
      addDataSourceForm: {
        host: '',
        type: 'MySQL',
        region: 'hangzhou',
        role: 'MASTER',
        instanceType: 'SELF_MAINTENANCE',
        sid: ''
      },
      searchKey: {
        host: '',
        region: '',
        dbType: 'all'
      },
      dataSourceColumn: [
        {
          title: this.$t('shu-ju-yuan-id'),
          key: 'instanceId',
          slot: 'instanceId',
          width: 280
        },
        {
          title: this.$t('lei-xing'),
          key: 'dataSourceType',
          width: 80,
          slot: 'dataSourceType'
        },
        {
          title: this.$t('shi-fou-kai-qi-cha-xun'),
          key: 'enableQuery',
          width: 100,
          slot: 'enableQuery'
        },
        // {
        //   title: this.$t('shu-ju-yuan-miao-shu'),
        //   key: 'instanceDesc',
        //   minWidth: 280
        // },
        {
          title: 'Host',
          key: 'host',
          minWidth: 500,
          slot: 'host'
        },
        {
          title: this.$t('huan-jing'),
          key: 'dsEnvId',
          width: 120,
          render: (h, params) => h('div', {}, this.getEnvById(params.row.dsEnvId))
        },
        // {
        //   title: this.$t('suo-you-zhe-0'),
        //   key: 'owner',
        //   width: 120
        // },
        {
          title: this.$t('cao-zuo'),
          key: '',
          slot: 'action',
          width: 300,
          fixed: 'right'
        }
      ],
      dataSourceColumnWithNoManage: [
        {
          title: this.$t('shu-ju-yuan-id'),
          key: 'instanceId',
          slot: 'instanceId',
          width: 280
        },
        {
          title: this.$t('lei-xing'),
          key: 'dataSourceType',
          width: 80,
          slot: 'dataSourceType'
        },
        // {
        //   title: this.$t('shu-ju-yuan-miao-shu'),
        //   key: 'instanceDesc',
        //   minWidth: 280
        // },
        {
          title: 'Host',
          key: 'host',
          minWidth: 500,
          slot: 'host'
        },
        {
          title: this.$t('huan-jing'),
          key: 'dsEnvId',
          width: 120,
          render: (h, params) => h('div', {}, this.getEnvById(params.row.dsEnvId))
        }
        // {
        //   title: this.$t('suo-you-zhe-0'),
        //   key: 'owner',
        //   width: 120
        // },
      ],
      dataSourceData: [],
      showData: [],
      pagingData: [],
      addDataSourceRule: {
        host: [
          {
            required: true,
            message: 'The host cannot be empty',
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: 'The type cannot be empty',
            trigger: 'change'
          }
        ],
        role: [
          {
            required: true,
            type: 'string',
            message: 'The role cannot be empty',
            trigger: 'change'
          }
        ],
        region: [
          {
            required: true,
            type: 'string',
            message: 'The region cannot be empty',
            trigger: 'change'
          }
        ],
        instanceType: [
          {
            required: true,
            message: 'Please select type',
            trigger: 'change'
          }
        ]
      }
    };
  },
  computed: {
    ifSupported() {
      return (record) => {
        let ifSUpported = false;
        let globalDsSetting = {};
        if (this.getDmProductClusterList.length > 0) {
          this.productClusterList.forEach((cluster) => {
            if (cluster.clusterCode === store.state.selectDmProductCluster) {
              globalDsSetting = cluster.config.dsSettingDef;
            }
          });
        } else {
          globalDsSetting = this.dmGlobalSetting.dsSettingDef;
        }
        if (globalDsSetting[record.dataSourceType]) {
          ifSUpported = true;
        }
        return ifSUpported;
      };
    },
    getDmProductClusterList() {
      const ccList = [];
      this.productClusterList.forEach((cluster) => {
        if (cluster.product === 'CloudDM') {
          ccList.push(cluster);
        }
      });
      return ccList;
    },
    ...mapState(['productClusterList', 'myAuth', 'deployEnvListMap', 'clusterListMap', 'userInfo', 'regionList', 'globalDsSetting', 'dmGlobalSetting']),
    ...mapGetters([
      'includesCC',
      'includesDM',
      'isDesktop'
    ]),
    getColumns() {
      if (this.myAuth.includes('DM_DS_MANAGE')) {
        return this.dataSourceColumn;
      } else {
        return this.dataSourceColumnWithNoManage;
      }
    },
    SECOND_CONFIRM_EVENT_LIST() {
      return SECOND_CONFIRM_EVENT_LIST;
    },
    DATASOURCE_DEPLOY_TYPE() {
      return DATASOURCE_DEPLOY_TYPE;
    },
    DATASOURCE_DEPLOY_TYPE_I18N() {
      return DATASOURCE_DEPLOY_TYPE_I18N;
    },
    showUpdateHttpHost() {
      return ['StarRocks', 'Doris', 'SelectDB'].includes(this.selectedRow.dataSourceType);
    },
    getEnvById() {
      return (id) => {
        let envName = '';
        this.envData.forEach((env) => {
          if (env.id === id) {
            envName = env.envName;
          }
        });
        return envName;
      };
    }
  },
  methods: {
    isOracle,
    handleCloseModal() {
      this.showKvConfigsModal = false;
    },
    async listEnv() {
      const data = {
        envName: ''
      };
      const res = await this.$services.listDsEnv({ data });
      if (res.success) {
        this.envData = res.data;
      }
    },
    async handleUpsertConfigs() {
      const updateConfigs = {};
      const needCreateConfigs = {};

      this.dsKvConfigs.forEach((config) => {
        const {
          needCreated, defaultValue, configValue, currentCount, configName
        } = config;

        if (needCreated) {
          needCreateConfigs[configName] = currentCount || defaultValue;
        } else {
          if (currentCount && currentCount !== configValue) {
            updateConfigs[configName] = currentCount;
          }
        }
      });

      const res = await upsertDsConfig({
        dataSourceId: this.selectedRow.id,
        needCreateConfigs,
        updateConfigs
      });

      if (res.data.success) {
        this.showKvConfigsModal = false;
        this.$Message.success(this.$t('xiu-gai-cheng-gong'));
      }
    },
    async handleKvConfigs(row) {
      this.selectedRow = row;
      const res = await queryDsConfig({
        dataSourceId: row.id
      });

      if (res.data.success) {
        this.dsKvConfigs = res.data.data;
        this.showKvConfigsModal = true;
      }
    },
    handleKeyTabFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024) {
          return false;
        }
        this.accountInfo.secretFile = file;
        this.$refs['account-info-form'].validateField('secretFile');
      }
    },
    handleEnableQuery(row) {
      if (row.enableQuery) {
        this.handleActions({ key: 'enableQuery' }, row);
      } else {
        this.handleActions({ key: 'disableQuery' }, row);
      }
    },
    async handleActions(action, row) {
      // console.log(action.key, row);
      // const res = await this.getDs(row.id);
      // if (res.success) {
      //   if (action.key === 'add_whitelist') {
      //     res.data.whiteListAddType = 'ADD_ALL';
      //   }
      //   this.selectedDatasource = res.data;
      // }
      this.selectedDatasource = row;
      switch (action.key) {
        case 'ticket':
          this.showTicketModal = true;
          break;
        case 'enableQuery':
          this.showEnableQueryModal = true;
          break;
        case 'disableQuery':
          Modal.confirm({
            title: this.$t('ting-yong-cha-xun-gong-neng-que-ren'),
            content: this.$t('que-ren-yao-ting-yong-gai-shu-ju-yuan-de-cha-xun-gong-neng-ma-ting-yong-hou-jiang-wu-fa-cha-xun-cao-zuo-huo-ti-jiao-gong-dan'),
            onOk: () => {
              this.handleDisableDsQuery(row);
            },
            onCancel: () => {
              this.handleCancelDisableQuery();
            }
          });
          break;
        case 'go_params':
          this.$router.push({ path: `/dmdatasource/params/${row.id}` });
          break;
        default:
          break;
      }
    },
    handleFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024) {
          return false;
        }
        this.accountInfo.securityFile = file;
        this.$refs['account-info-form'].validateField('securityFile');
      }
    },
    handleCaFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024) {
          return false;
        }
        this.accountInfo.securityFile = file;
        this.accountInfo.caFile = file;
        // this.$refs['account-info-form'].validateField('caFile');
      }
    },
    handleRefresh() {
      this.getDataSourceList();
    },
    async getDataSourceList(searchKey) {
      searchKey = this.searchKey;
      this.refreshLoading = true;
      let type = null; let hostType = null; let
        deployType = null;

      if (searchKey && searchKey.dbType !== 'all') {
        type = searchKey.dbType;
      }
      if (searchKey && searchKey.hostType !== 'all') {
        hostType = searchKey.hostType;
      }
      if (searchKey && searchKey.deployType !== 'all') {
        deployType = searchKey.deployType;
      }
      const res = await this.$services.getDmDsList({
        data: {
          useVisibility: true,
          type,
          deployType,
          hostType,
          instanceIdLike: searchKey ? searchKey.instanceIdLike : null,
          dataSourceDescLike: searchKey ? searchKey.dataSourceDescLike : null,
          dsHostLike: searchKey ? searchKey.dsHostLike : null,
          dataSourceId: searchKey ? searchKey.dataSourceId : null
        }
      });
      this.refreshLoading = false;
      if (res.success) {
        this.dataSourceData = res.data;
        this.pagingData = _.cloneDeep(this.dataSourceData);
        this.total = this.dataSourceData.length;
        this.showData = this.dataSourceData.slice((this.page - 1) * this.size, this.page * this.size);
        this.showData.map((item) => {
          item.showEditDesc = false;
          return null;
        });
      }
    },
    handleShowAddDataSource() {
      this.$router.push({ path: '/ccdatasource/add' });
    },
    guid() {
      let num = '';

      for (let i = 0; i < 16; i++) {
        num += Math.floor(Math.random() * 10);
      }
      return num;
    },
    handlePageChange(page) {
      this.page = page;
      this.showData = this.pagingData.slice((this.page - 1) * this.size, this.page * this.size);
      this.showData.map((item) => {
        item.showEditDesc = false;
        return null;
      });
    },
    handleCancelEdit() {
      this.publicHost = '';
      this.publicHttpHost = '';
      this.privateHost = '';
      this.privateHttpHost = '';
      this.showEditDesc = false;
      this.showEditAccount = false;
      this.showAddWhiteList = false;
      this.showEditPrivateHost = false;
      this.showEditPublicHost = false;
      store.state.selectedCluster.selectedCluster = {};
      this.showUpdateAKSK = false;
    },
    handleEditDataSourceDesc(row) {
      this.instanceDesc = row.instanceDesc;
      this.selectedRow = row;
      this.showEditDesc = true;
    },
    handlePageSizeChange(size) {
      this.size = size;
      this.handlePageChange(1);
    },
    getDataSourceDetail(row, security = false) {
      queryById({ dataSourceId: row.id })
        .then((res) => {
          if (res.data.code === '1') {
            this.sourceDetail = res.data.data;
            this.accountInfo.account = this.sourceDetail.accountName;
            this.accountInfo.securityType = this.sourceDetail.securityType;

            if (security) {
              console.log(res.data.data);
              const {
                deployType,
                dataSourceType
              } = res.data.data;
              dsSecurityOption({
                deployEnvType: deployType,
                dataSourceType
              })
                .then((res2) => {
                  if (res2.data.code === '1') {
                    this.securitySetting = res2.data.data.securityOptions;
                    const obj = {};
                    res2.data.data.securityOptions.forEach((s) => {
                      obj[s.securityType] = s;
                    });
                    this.securitySettingObj = obj;
                  }
                });
            }
          }
        });
    },
    confirmEditAccount() {
      this.$refs['account-info-form'].validate((valid) => {
        console.log(valid);
        if (valid) {
          const formData = new FormData();
          const datasourceUpdateData = {
            dataSourceId: this.sourceDetail.id,
            userName: this.accountInfo.account,
            password: this.accountInfo.password,
            securityType: this.accountInfo.securityType
          };
          formData.append('DataSourceUpdateData', JSON.stringify(datasourceUpdateData));
          formData.append('securityFile', this.accountInfo.securityFile);
          formData.append('secretFile', this.accountInfo.secretFile);
          console.log(formData.get('DataSourceUpdateData'));
          updateAccountAndPassword(formData)
            .then((res) => {
              if (res.data.code === '1') {
                this.showEditAccount = false;
                this.getDataSourceList();
                this.accountInfo = {
                  account: '',
                  password: '',
                  securityType: ''
                };
                this.$Message.success(this.$t('xiu-gai-cheng-gong'));
              }
            });
        }
      });
    },
    handleEditAccount(row) {
      this.showEditAccount = true;
      this.sourceDetail = row;
      this.getDataSourceDetail(row, true);
    },
    handleUpdateAKSK(row) {
      this.showUpdateAKSK = true;
      this.sourceDetail = row;
    },
    handleShowAddWhiteList(row) {
      this.showAddWhiteList = true;
      this.selectedRow = row;
      if (this.selectedRow.publicHost) {
        this.selectedRow.whiteListAddType = 'PUBLIC_IP_ONLY';
      } else {
        this.selectedRow.whiteListAddType = 'PRIVATE_IP_ONLY';
      }
    },
    handleChangeType(type) {
      this.selectedRow.whiteListAddType = type;
    },
    handleGetWorkerCluster(data) {
      listClusterByCondition({}, { customeHeaders: { 'X-Product-Code': data } }).then((res) => {
        if (res.data.success) {
          this.workerClusterList = res.data.data;
        }
      });
    },
    confirmAddWhiteList() {
      this.showAddWhiteList = false;
      this.currentPercentage = 0;
      this.currentAddWhiteListStatus = '';
      const ids = [];
      const
        that = this;

      store.state.selectedCluster[this.selectedRow.instanceId].map((item) => {
        ids.push(item.id);
        return null;
      });
      addClusterWhiteList({
        dataSourceId: this.selectedRow.id,
        dataSourceType: this.selectedRow.dataSourceType,
        clusterIds: ids,
        whiteListAddType: this.selectedRow.whiteListAddType,
        dataSourceRegion: this.selectedRow.region
      })
        .then((res) => {
          if (res.data.code === '1' && this.dataIsNaN(res.data.data)) {
            this.showAddWhiteListProgress = true;

            this.querySourceAddWhiteListInterval = setInterval(() => {
              queryConsoleJob({ consoleJobId: res.data.data })
                .then((response) => {
                  if (res.data.code === '1') {
                    that.currentAddWhiteListStatus = response.data.data.taskState;
                    if (response.data.data.taskState === 'SUCCESS') {
                      clearInterval(that.querySourceAddWhiteListInterval);
                      that.sourceDisabled = false;
                      setTimeout(() => {
                        that.showAddWhiteListProgress = false;
                      }, 500);
                    } else if (response.data.data.taskState === 'FAILED') {
                      clearInterval(that.querySourceAddWhiteListInterval);
                      that.sourceDisabled = false;
                    }
                  }
                });
            }, 3000);
            store.state.selectedCluster.selectedCluster = {};
          } else if (res.data.code === '6028') {
            this.$refs.stToken.handleShowAkSk();
          } else if (res.data.code === '2011') {
            this.$refs.aliyunAKSK.handleShowAkSk();
          }
        });

      this.getPercantage = setInterval(() => {
        if (that.currentAddWhiteListStatus === 'SUCCESS') {
          that.currentPercentage = 100;
          clearInterval(that.getPercantage);
          setTimeout(() => {
            that.showAddWhiteList = false;
          }, 500);
        } else if (that.currentAddWhiteListStatus === 'FAILED') {
          clearInterval(that.getPercantage);
        } else if (that.currentPercentage < 90) {
          that.currentPercentage += Math.floor(Math.random() * 3);
        } else if (that.currentPercentage < 97 && that.currentPercentage >= 90) {
          that.currentPercentage += Math.floor(Math.random() * 1.5);
        } else if (that.currentPercentage >= 97) {
          that.currentPercentage = 99;
        }
      }, 200);
    },
    handleCloseTestConnectionModal() {
      this.showTestConnectionModal = false;
    },
    handleShowTestConnectionModal(row) {
      this.showTestConnectionModal = true;
      this.selectedRow = row;
    },
    handleUpdatePrivateHost(row) {
      this.showEditPrivateHost = true;
      this.selectedRow = row;
      this.privateHost = row.privateHost;
      if (row.extraVO) {
        this.privateHttpHost = row.extraVO.privateStarRocksHttpHost;
      }
    },
    handleGoConsoleJob(row) {
      this.$router.push({ path: `/ccsystem/state/task/${row.consoleJobId}` });
    },
    dataIsNaN(value) {
      return typeof value === 'number' && !Number.isNaN(value);
    },
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchKey = {};
    },
    handleClose() {
      this.showEnableQueryModal = false;
      this.selectedDatasource.enableQuery = false;
    },
    handleCancelDisableQuery() {
      this.selectedDatasource.enableQuery = true;
    },
    async handleDisableDsQuery(row) {
      const res = await this.$services.disableDsQuery({
        data: {
          dataSourceId: row.id
        }
      });
      if (res.success) {
        this.$message.success(this.$t('ting-yong-cheng-gong'));
        this.getDataSourceList();
      }
    },
    hideTicketModal() {
      this.showTicketModal = false;
    }
  }
};
</script>
<style lang="less" scoped>
.data-source-container {
  position: relative;
  margin-top: 16px;
  margin-bottom: 60px;

  .iconfont {
    color: #8D95A6;
    cursor: pointer;
    font-size: 12px;
  }

  .show-datasource-info-icon {
    color: #0BB9F8;
    font-size: 20px;
    position: absolute;
    right: 0;
    top: -6px;
    cursor: pointer;
  }

  .datasource-desc-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 200px;
  }
}

.add-white-list-container {
  width: 280px;
  border: 1px solid #DADADA;
  padding: 0 12px;
  border-radius: 4px;
}

.host-type {
  padding: 12px 0;
}

.host-type-label {
  font-size: 12px;
  color: #333;
  background-color: #DEEFFF;
  display: inline-block;
  //width: 16px;
  height: 16px;
  border-radius: 4px;
  text-align: center;
  line-height: 16px;
  margin-right: 4px;
}

.alarm-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  /*border-radius: 50%;*/
  /*background-color: #FF6E0D;*/
  color: #FF6E0D;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  margin-left: 4px;

  .iconyibuforce {
    font-size: 14px;
    color: #FF6E0D;
  }
}

.datasource-operation-dropdown {
  padding: 0!important;
}

.dropdown-content {
  padding: 7px 16px;
  display: block;
}

.active {
  color: #52C41A;
}

.inactive {
  color: #aaaaaa;
}
</style>
