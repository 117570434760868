<template>
  <div class="datasource">
    <Breadcrumb style="margin-bottom: 14px;">
      <BreadcrumbItem>{{ $t('cha-xun-pei-zhi') }}</BreadcrumbItem>
    </Breadcrumb>
    <query-header :handle-click-add-btn="handleClickAddBtn" :handle-query="handleQuery"
                  :handle-refresh="getDsList"
                  :query="queryForm" :select-options="Object.values(deployEnvListMap)"
                  :show-add-btn="false"
                  :add-btn-text="$t('tian-jia-shu-ju-yuan')" :placeholder="$t('qing-shu-ru-shi-li-id-ming-cheng-cha-xun')"/>
    <a-table :columns="datasourceColumns" :data-source="datasourceList"
             :locale="{emptyText: $t('zan-wu-shu-ju')}"
             :rowKey="record => record.id"
             size="small">
      <template slot="instanceId" slot-scope="record">
        <Tooltip style="margin-left: 10px;cursor: pointer" placement="right"
                 class="dataSource-icon" transfer
                 :content="`${record.deployEnvType==='ALIBABA_CLOUD_HOSTED'?Mapping.aliyunType[record.dataSourceType]:record.dataSourceType}`">
          <data-source-icon :type="record.dataSourceType"
                          :instanceType="record.deployEnvType"></data-source-icon>
        </Tooltip>
        {{ record.instanceId }}
        <cc-iconfont v-if="record.consoleTaskState==='FAILED'" color="#FF6E0D"
                     name="job-error" @click.native="handleGoConsoleJob(record)"/>
        <cc-iconfont :size="12" name="detail" @click.native="getDsDetail(record)"/>
        <a-popover placement="right" :mouse-leave-delay="0" v-model="record.showPopover">
          <template slot="content">
            <div class="datasource-detail" id="popover">
              <div class="info" style="display: flex;flex-direction: column;">
                <section>
                  <div>
                    <span>{{ $t('host-0') }}</span>{{ selectedDatasource.defaultHost }}
                  </div>
                </section>
                <section>
                  <div><span>{{ $t('bu-shu-qing-kuang-0') }} </span>
                    {{
                      CLUSTER_TYPE[selectedDatasource.deployEnvType] && CLUSTER_TYPE[selectedDatasource.deployEnvType].name
                    }}
                  </div>
                  <div><span>{{ $t('ming-cheng-0') }} </span>{{ selectedDatasource.instanceDesc }}</div>
                </section>
                <section>
                  <div><span>{{ $t('wang-luo-qu-yu') }} </span>{{ regionList[selectedDatasource.region] }}</div>
                  <div><span>{{ $t('ban-ben-hao-0') }} </span>{{ selectedDatasource.version }}</div>
                </section>
                <section>
                  <div><span>{{ $t('id') }}</span>{{ selectedDatasource.instanceId }}</div>
                  <div>
                    <span>{{ $t('lei-xing-0') }} </span>
                    <cc-data-source-icon :instanceType="selectedDatasource.deployEnvType" :size="18"
                                         :type="selectedDatasource.dataSourceType"
                                         color="#4DBAEE"/>
                  </div>
                </section>
                <section>
                  <div><span>{{ $t('wang-luo-qing-kuang') }} </span>{{
                      selectedDatasource.defaultHost === selectedDatasource.privateHost ? $t('si-you') : $t('gong-you')
                    }}
                  </div>
                  <div><span>{{ $t('tian-jia-shi-jian') }} </span>{{
                      selectedDatasource.gmtCreate | formatTime('YYYY-MM-DD hh:mm:ss')
                    }}
                  </div>
                </section>
              </div>
              <div class="cluster">
                <section>
                  <div><span>{{ $t('bang-ding-de-ji-qun') }} </span>{{ selectedDatasource.bindClusterName }}
                  </div>
                </section>
              </div>
              <div v-if="selectedDatasource.whitelist && selectedDatasource.whitelist.length"
                   class="white">
                <div style="color: #888;font-weight: bold">{{ $t('bai-ming-dan') }}</div>
                <div style="display: flex;flex-wrap: wrap;">
                  <div v-for="white in selectedDatasource.whitelist" :key="white"
                       style="width: 25%;margin-top: 5px;">
                    {{ white }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </a-popover>
      </template>
      <template slot="instanceName" slot-scope="record">
        {{ record.instanceDesc }}
<!--        <cc-iconfont v-if="userInfo.authArr.includes('DS_UPDATE_DESC')" :size="8" name="edit"-->
<!--                     style="margin-right: 4px;margin-left: 4px;"-->
<!--                     @click.native="handleEditDatasourceDesc(record)"/>-->
      </template>
      <template slot="envType" slot-scope="record">
        {{ deployEnvListMap[record.deployEnvType] && deployEnvListMap[record.deployEnvType].name }}
      </template>
      <template slot="status" slot-scope="record">
        <cc-status :color="LIFECYCLE[record.lifeCycleState].color"
                   :content="LIFECYCLE[record.lifeCycleState].label"/>
      </template>
      <template slot="actions" slot-scope="record">
        {{''}}
        <a-button
          size="small" type="link" :disabled="!ifSupported(record)"
          @click.native="handleActions( {key:'enableQuery'}, record)"
          v-if="!isDesktop && !record.enableQuery">
          {{ $t('qi-yong-cha-xun-gong-neng') }}
        </a-button>
        <a-button
          size="small" type="link"
          @click.native="handleActions( {key:'disableQuery'}, record)"
          v-if="!isDesktop && record.enableQuery">
          {{ $t('ting-yong-cha-xun-gong-neng') }}
        </a-button>
        <a-button
          size="small" type="link"
          @click.native="handleActions( {key:'ticket'}, record)"
          v-if="!isDesktop && record.enableQuery">
          {{ $t('gong-dan-shen-pi-liu-cheng') }}
        </a-button>
        <a-button
          size="small" type="link"
          @click.native="handleActions( {key:'go_params'}, record)"
          v-if="userAuthArr.includes('DS_UPDATE_ACCOUNT') && record.enableQuery"
          :disabled="record.lifeCycleState !== 'CREATED'">
          {{ $t('xiu-gai-can-shu-pei-zhi') }}
        </a-button>
<!--        <a-button-->
<!--          v-if="record.deployEnvType === CLUSTER_ENV.ALIBABA_CLOUD_HOSTED && userAuthArr.includes('ALIYUN_RDS_ADD_WHITELIST') && record.enableQuery"-->
<!--          :disabled="record.lifeCycleState !== 'CREATED'"-->
<!--          size="small" type="link"-->
<!--          @click.native="handleActions( {key:'add_whitelist'}, record)">-->
<!--          {{ $t('tian-jia-bai-ming-dan') }}-->
<!--        </a-button>-->
<!--        <a-dropdown-->
<!--          :trigger="['click']">-->
<!--          {{''}}-->
<!--          <a-button type="link"-->
<!--                    v-if="userAuthArr.includes('DS_UPDATE_CONFIG') || userAuthArr.includes('DS_UPDATE_ACCOUNT') || userAuthArr.includes('DS_DELETE')">-->
<!--            更多-->
<!--          </a-button>-->
<!--          <a-menu slot="overlay" @click="handleActions($event, record)">-->
<!--            <a-menu-item-->
<!--              v-if="userInfo.authArr.includes('DS_UPDATE_CONFIG')&&!isDesktop"-->
<!--              key="modify_datasource"-->
<!--              :disabled="record.lifeCycleState !== 'CREATED'">-->
<!--              编辑数据源-->
<!--            </a-menu-item>-->
<!--            <a-menu-item-->
<!--              v-if="userInfo.authArr.includes('DS_UPDATE_HOST')&&!isDesktop"-->
<!--              :disabled="record.lifeCycleState !== 'CREATED'" key="modify_address">-->
<!--              修改网络地址-->
<!--            </a-menu-item>-->
<!--            <a-menu-item v-if="userInfo.authArr.includes('DS_UPDATE_ACCOUNT')"-->
<!--                         key="modify_datasource_account"-->
<!--                         :disabled="record.lifeCycleState !== 'CREATED'">-->
<!--              修改数据库账号-->
<!--            </a-menu-item>-->
<!--            <a-menu-item v-if="userInfo.authArr.includes('DS_DELETE')" key="delete_datasource">-->
<!--              删除数据源-->
<!--            </a-menu-item>-->
<!--          </a-menu>-->
<!--        </a-dropdown>-->
      </template>
    </a-table>
    <a-modal v-model="showModifyIpModal" :mask-closable=false :width="402" footer-hide
             :title="$t('xiu-gai-wang-luo-di-zhi')">
      <div v-if="showModifyIpModal" class="edit-ip-modal">
        <div class="address">
          <div class="title">{{ $t('nei-wang-di-zhi') }}</div>
          <div class="ip">
            <a-input v-model="selectedDatasource.privateHost" :placeholder="$t('nei-wang-ip-huo-yu-ming')"
                     style="width:280px;"/>
          </div>
        </div>
        <div class="address">
          <div class="title">{{ $t('wai-wang-di-zhi') }}</div>
          <div class="ip">
            <a-input v-model="selectedDatasource.publicHost" :placeholder="$t('wai-wang-ip-huo-yu-ming')"
                     style="width:280px;"/>
          </div>
        </div>
        <div class="address">
          <div class="title">{{ $t('mo-ren-fang-wen-di-zhi') }}</div>
          <div class="ip">
            <a-radio-group v-model="selectedDatasource.defaultHostType">
              <a-radio value="private">
                {{ $t('nei-wang') }}
              </a-radio>
              <a-radio value="public">
                {{ $t('wai-wang') }}
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="footer">
          <a-button type="primary" @click="handleChangeHost">{{ $t('bao-cun') }}</a-button>
          <a-button @click="showModifyIpModal=false">{{ $t('qu-xiao') }}</a-button>
        </div>
      </div>
    </a-modal>
    <modify-ds-account-modal :visible="showModifyDatasourceAccountModal"
                             :handle-close-modal="hideModifyDatasourceAccountModal"
                             :ds="selectedDatasource" v-if="showModifyDatasourceAccountModal"/>
    <a-modal v-model="showModifyDatasourceModal" :closable="false" :mask-closable="false"
             :width="420" :title="$t('bian-ji-shu-ju-yuan')">
      <div v-if="showModifyDatasourceModal" class="edit-datasource-modal">
        <a-form-model :label-col="labelCol" :model="selectedDatasource" :wrapper-col="wrapperCol">
          <a-form-model-item :label="$t('bu-shu-lei-xing')">
            {{ CLUSTER_TYPE[selectedDatasource.deployEnvType].name }}
          </a-form-model-item>
          <a-form-model-item :label="$t('shu-ju-ku-lei-xing')">
            <cc-data-source-icon :instanceType="selectedDatasource.deployEnvType" :size="18"
                                 :type="selectedDatasource.dataSourceType"
                                 color="#4DBAEE"></cc-data-source-icon>
            {{ selectedDatasource.dataSourceType }}
          </a-form-model-item>
          <a-form-model-item :label="$t('shi-li-id')">
            <a-input v-model="selectedDatasource.instanceId" style="width: 240px;"></a-input>
          </a-form-model-item>
          <a-form-model-item :label="$t('bang-ding-ji-qun')">
            <a-select v-model="selectedDatasource.bindClusterId" :dropdownMatchSelectWidth="false"
                      :placeholder="$t('qing-xuan-ze')"
                      style="width: 240px;">
              <a-select-option v-for="cluster in Object.values(clusterListMap)" :key="cluster.value"
                               :disabled="!cluster.runningCount" :value="cluster.value">
                {{ cluster.name }}/{{ cluster.desc }} {{ $t('shu-liang') }}:{{
                  cluster.runningCount
                }}/{{ cluster.workerCount }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item :label="$t('yun-hang-huan-jing')">
            <a-select v-model="selectedDatasource.dsEnvId" :placeholder="$t('qing-xuan-ze')"
                      style="width: 240px;">
              <a-select-option v-for="env in Object.values(envListMap)" :key="env.id"
                               :value="env.id">
                {{ env.envName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <!--        <step-one :handle-update-ds="handleUpdateDs" :stepOneDataProps="this.selectedDatasource" edit/>-->
        <div class="footer">
          <a-button type="primary" @click="updateDs">{{ $t('bao-cun') }}</a-button>
          <a-button @click="showModifyDatasourceModal=false">{{ $t('qu-xiao') }}</a-button>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="showEditDatasourceDesc" :closable="false" :width="402" cancelText="取消"
             okText="保存" wrapClassName="have-footer" @ok="handleUpdateDsDesc">
      <a-input v-model="selectedDatasource.instanceDesc" type="textarea"/>
    </a-modal>
    <a-modal v-model="showEditWhiteIpModal" :title="$t('tian-jia-bai-ming-dan')">
      <div style="margin-bottom: 10px;display: flex;align-items: center;">
        <div style="margin-right: 10px;">{{ $t('bai-ming-dan-lei-xing') }}</div>
        <a-radio-group v-model="selectedDatasource.whiteListAddType">
          <a-radio size="small" value="ADD_ALL">
            {{ $t('quan-bu') }}
          </a-radio>
          <a-radio size="small" value="PRIVATE_IP_ONLY">
            {{ $t('nei-wang-ip') }}
          </a-radio>
          <a-radio size="small" value="PUBLIC_IP_ONLY">
            {{ $t('gong-wang-chu-kou-ip') }}
          </a-radio>
        </a-radio-group>
      </div>

      <div class="worker-detail">
        <div class="circle">
          <cc-iconfont name="machine" size="16"/>
        </div>
        <div class="content">
          <div class="first">
            <div>{{ selectedCluster.clusterDesc }}</div>
            <div>{{ selectedCluster.clusterName }}</div>
          </div>
          <div class="second">
            <div>
              {{
                CLUSTER_TYPE[selectedCluster.deployEnvType] && CLUSTER_TYPE[selectedCluster.deployEnvType].name
              }}
            </div>
            <div>
              <cc-iconfont :size="12" name="location" style="margin-right: 2px;"/>
              {{ regionList[selectedCluster.region] }}
            </div>
          </div>
        </div>
      </div>
      <a-table :columns="whitelistColumns" :data-source="selectedClusterWorkerList"
               :locale="{ emptyText: $t('zan-wu-shu-ju')}"
               :row-key="record => record.id" size="small">
        <template slot="name" slot-scope="record">
          {{ record.id }}/{{ record.workerName }}
        </template>
        <template slot="white" slot-scope="record">
          <div :style="isWhiteAdded(record) ? 'color: #52C41A;' : 'color: #999;'">
            {{ isWhiteAdded(record) ? $t('yi-tian-jia') : $t('wei-tian-jia') }}
          </div>
        </template>
      </a-table>
      <div class="footer">
        <a-button type="primary" @click="addClusterWhiteList">{{ $t('bao-cun') }}</a-button>
        <a-button @click="showEditWhiteIpModal=false">{{ $t('qu-xiao') }}</a-button>
      </div>
    </a-modal>
    <a-modal v-model="showDeleteDatasourceModal" :width="400" :title="$t('shan-chu')">
      {{ $t('shi-fou-yao-shan-chu-ming-cheng-wei-selecteddatasourceinstancedesc-de-shu-ju-yuan', [selectedDatasource.instanceDesc]) }}
      <div class="footer">
        <a-button type="primary" @click="handleDeleteDatasource">{{ $t('shan-chu') }}</a-button>
        <a-button @click="showDeleteDatasourceModal=false">{{ $t('qu-xiao') }}</a-button>
      </div>
    </a-modal>
    <ticket-modal :visible="showTicketModal" :handle-close-modal="hideTicketModal"
                  :ds="selectedDatasource"
                  v-if="showTicketModal"/>
    <EnableQueryModal :visible="showEnableQueryModal" :datasource="selectedDatasource" :handle-close-modal="handleClose" :get-ds-list="getDsList" type="dataSourceList"></EnableQueryModal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Modal } from 'ant-design-vue';
import cloneDeep from 'lodash.clonedeep';
import { CLUSTER_ENV, CLUSTER_TYPE } from '@/const';
import { ACTIONS_TYPE } from '@/store/actions';
import datasourceMixin from '@/mixins/datasourceMixin';
import TicketModal from '@/views/system/datasource/components/TicketModal';
import ModifyDsAccountModal from '@/views/system/datasource/components/ModifyDsAccountModal';
import EnableQueryModal from '@/components/function/addDataSource/EnableQueryModal';
import Mapping from '@/views/util';
import QueryHeader from '../components/QueryHeader';
// import {restartJob} from "@/services/cc/api/job";

export default {
  name: 'DataSourceList',
  components: {
    ModifyDsAccountModal,
    TicketModal,
    QueryHeader,
    EnableQueryModal
  },
  computed: {
    ...mapGetters(['isDesktop']),
    isWhiteAdded() {
      return (worker) => {
        let isAdded = false;
        switch (this.selectedDatasource.whiteListAddType) {
          case 'ADD_ALL':
            isAdded = this.selectedDsWhitelist.includes(worker.privateIp) && this.selectedDsWhitelist.includes(worker.publicIp);
            break;
          case 'PRIVATE_IP_ONLY':
            isAdded = this.selectedDsWhitelist.includes(worker.privateIp);
            break;
          case 'PUBLIC_IP_ONLY':
            isAdded = this.selectedDsWhitelist.includes(worker.publicIp);
            break;
          default:
            break;
        }

        return isAdded;
      };
    },
    ifSupported() {
      return (record) => {
        let ifSUpported = false;
        if (this.globalDsSetting[record.dataSourceType]) {
          ifSUpported = true;
        }
        return ifSUpported;
      };
    },
    ...mapState({
      deployEnvListMap: (state) => state.deployEnvListMap,
      clusterListMap: (state) => state.clusterListMap,
      userInfo: (state) => state.userInfo,
      regionList: (state) => state.regionList,
      globalDsSetting: (state) => state.globalDsSetting
    }),
    datasourceColumns() {
      const {
        deployEnvType,
        query
      } = this.datasourceFilterInfo;
      return [
        {
          title: this.$t('shi-li-id'),
          key: 'instanceId',
          filteredValue: [query] || null,
          onFilter: (value, record) => record.instanceId.includes(value),
          scopedSlots: { customRender: 'instanceId' }
        }, {
          title: this.$t('shi-li-ming-cheng'),
          key: 'instanceDesc',
          filteredValue: [query] || null,
          onFilter: (value, record) => record.instanceDesc.includes(value),
          scopedSlots: { customRender: 'instanceName' }
        }, {
          title: this.$t('lei-xing'),
          filteredValue: [deployEnvType] || null,
          key: 'envType',
          // onFilter: (value, record) => value === 'all' || record.deployEnvType.includes(value),
          scopedSlots: { customRender: 'envType' }
        }, {
          dataIndex: 'dsEnvName',
          title: this.$t('yun-hang-huan-jing'),
          key: 'dsEnvName'
        }, {
          title: this.$t('zhuang-tai'),
          key: 'status',
          scopedSlots: { customRender: 'status' }
        }, {
          title: this.$t('cao-zuo'),
          // key: 'action',
          fixed: 'right',
          width: 300,
          scopedSlots: { customRender: 'actions' }
        }];
    }
  },
  mixins: [datasourceMixin],
  data() {
    return {
      Mapping,
      userAuthArr: [],
      hasPopover: false,
      showTicketModal: false,
      showEnableQueryModal: false,
      selectedCluster: {},
      selectedClusterWorkerList: [],
      envListMap: {},
      CLUSTER_TYPE,
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      LIFECYCLE: {
        CREATED: {
          label: this.$t('yi-wan-cheng'),
          color: '#52C41A'
        },
        CREATING: {
          label: this.$t('chuang-jian-zhong-0'),
          color: '#FFA30E'
        },
        DELETING: {
          label: this.$t('shan-chu-zhong'),
          color: '#FF6E0D'
        },
        DELETED: {
          label: this.$t('yi-shan-chu'),
          color: '#999'
        },
        LOCKED: {
          label: this.$t('yi-suo-ding'),
          color: '#FF1815'
        }
      },
      queryForm: {
        type: 'all',
        text: ''
      },
      datasourceFilterInfo: {
        deployEnvType: '',
        query: ''
      },
      CLUSTER_ENV,
      selectedDatasource: {},
      showModifyIpModal: false,
      showModifyDatasourceModal: false,
      showEditDatasourceDesc: false,
      showModifyDatasourceAccountModal: false,
      showEditWhiteIpModal: false,
      showDeleteDatasourceModal: false,
      datasourceList: [],
      dataSourceTableMoreActions: [
        {
          name: this.$t('bian-ji-shu-ju-yuan'),
          value: 'modify_datasource'
        }, {
          name: this.$t('this-t-xiu-gai-shu-ju-ku-zhang-hao'),
          value: 'modify_datasource_account'
        },
        // {
        //   name: '删除数据库账号',
        //   value: 'delete_datasource_account'
        // },
        {
          name: this.$t('shan-chu-shu-ju-yuan'),
          value: 'delete_datasource'
        }
      ],
      accountRules: {
        userName: [
          {
            required: true,
            message: this.$t('zhang-hao-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('mi-ma-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ]
      },
      whitelistColumns: [
        {
          title: this.$t('ji-qi-id-ming-cheng'),
          scopedSlots: { customRender: 'name' }
        }, {
          title: this.$t('bai-ming-dan-0'),
          scopedSlots: { customRender: 'white' }
        }
      ],
      selectedDsWhitelist: []
    };
  },
  mounted() {
    document.addEventListener('click', this.hidePopover);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hidePopover);
  },
  methods: {
    hidePopover(e) {
      if (this.hasPopover) {
        const node = document.getElementById('popover');
        if (e.className !== 'cc-iconfont i-clouddm icon-detail' && node && !node.contains(e.target)) {
          this.datasourceList.forEach((ds) => {
            if (ds.id === this.selectedDatasource.id) {
              ds.showPopover = false;
              this.hasPopover = false;
              this.$forceUpdate();
            }
          });
        }
      }
    },
    async addClusterWhiteList() {
      const {
        id,
        whiteListAddType
      } = this.selectedDatasource;
      const data = {
        clusterIds: [this.selectedDatasource.bindClusterId],
        dataSourceId: id,
        whiteListAddType
      };

      const res = await this.$services.addClusterWhiteList({
        data,
        msg: this.$t('tian-jia-bai-ming-dan-cheng-gong')
      });
      if (res.success) {
        this.showEditWhiteIpModal = false;
      }
    },
    async getClusterDetail(clusterId) {
      const res = await this.$services.getCluster({ data: { clusterId } });
      if (res.success) {
        this.selectedCluster = res.data;
      }
    },
    async getWorkerList(clusterId) {
      const res = await this.$services.getWorkerList({ data: { clusterId } });

      if (res.success) {
        this.selectedClusterWorkerList = res.data;
      }
    },
    async queryWhiteList(dataSourceId) {
      const res = await this.$services.queryWhiteList({ data: { dataSourceId } });
      if (res.success) {
        this.selectedDsWhitelist = res.data;
      }
    },
    hideModifyDatasourceAccountModal() {
      this.showModifyDatasourceAccountModal = false;
    },
    async getDsDetail(ds) {
      if (ds.showPopover) {
        ds.showPopover = false;
        this.hasPopover = false;
        this.$forceUpdate();
      } else {
        const res = await this.getDs(ds.id);
        if (res.success) {
          const datasource = res.data;

          if (ds.deployEnvType === 'ALIBABA_CLOUD_HOSTED') {
            const res2 = await this.$services.queryWhiteList({ data: { dataSourceId: ds.id } });
            if (res2.success) {
              datasource.whitelist = res2.data;
            }
          }

          ds.showPopover = true;
          this.hasPopover = true;

          this.selectedDatasource = cloneDeep(datasource);
        }
      }
    },
    async handleDeleteDatasource() {
      const res = await this.$services.deleteDs({
        data: { dataSourceId: this.selectedDatasource.id },
        msg: this.$t('shan-chu-shu-ju-yuan-cheng-gong')
      });

      if (res.success) {
        await this.getDsList();
        this.showDeleteDatasourceModal = false;
      }
    },
    async updateDs() {
      const {
        id,
        bindClusterId,
        dsEnvId,
        instanceId
      } = this.selectedDatasource;
      const data = {
        clusterId: bindClusterId,
        dataSourceId: id,
        envId: dsEnvId,
        instanceId
      };

      const res = await this.$services.updateDsBindingAndEnv({
        data,
        msg: ''
      });

      if (res.success) {
        this.showModifyDatasourceModal = false;
        await this.getDsList();
      }
    },
    async listEnv() {
      const res = await this.$services.listDsEnv({ data: { envName: '' } });
      if (res.success) {
        const temp = {};
        res.data.forEach((env) => {
          temp[env.id] = env;
        });
        this.envListMap = temp;
      }
    },
    handleEditDatasourceDesc(ds) {
      this.selectedDatasource = cloneDeep(ds);
      this.showEditDatasourceDesc = true;
    },
    async handleUpdateDsDesc() {
      const {
        id,
        instanceDesc
      } = this.selectedDatasource;
      const res = await this.$services.updateDsDesc({
        data: {
          dataSourceId: id,
          desc: instanceDesc
        },
        msg: this.$t('geng-xin-shu-ju-ku-ming-cheng-cheng-gong')
      });

      if (res.success) {
        this.showEditDatasourceDesc = false;
        this.getDsList();
      }
    },
    hideTicketModal() {
      this.showTicketModal = false;
    },
    async handleUpdateDs() {
      // const {} = ds;
    },
    async handleDisableDsQuery(row) {
      const res = await this.$services.disableDsQuery({
        data: {
          dataSourceId: row.id
        }
      });
      if (res.success) {
        this.$message.success(this.$t('ting-yong-cheng-gong'));
        this.getDsList();
      }
    },
    _testConnection() {
      this.$refs.accountForm.validate(async (valid) => {
        if (valid) {
          const {
            bindClusterId,
            dataSourceType,
            userName,
            password,
            region,
            privateHost,
            publicHost,
            defaultHost,
            deployEnvType
          } = this.selectedDatasource;
          const data = {
            bindClusterId,
            dataSourceType,
            region,
            privateHost,
            publicHost,
            defaultHost,
            deployEnvType,
            dsPropsJson: JSON.stringify({
              userName,
              password
            }),
            securityType: 'USER_PASSWD'
          };
          this.testConnection(data);
        } else {
          return false;
        }
      });
    },
    async handleUpdateDsAccount() {
      const {
        id,
        userName,
        password
      } = this.selectedDatasource;
      const data = {
        dataSourceId: id,
        dsPropsJson: JSON.stringify({
          userName,
          password
        })
      };

      const res = await this.$services.updateDsAccount({
        data,
        msg: this.$t('geng-xin-zhang-hao-cheng-gong')
      });

      if (res.success) {
        this.showModifyDatasourceAccountModal = false;
        await this.getDsList();
        this.hideAccountModal();
      }
    },
    async handleChangeHost() {
      const {
        id,
        defaultHostType,
        privateHost,
        publicHost
      } = this.selectedDatasource;
      const res = await this.$services.updateDsHosts({
        data: {
          dataSourceId: id,
          defaultHost: defaultHostType === 'private' ? privateHost : publicHost,
          publicHost,
          privateHost
        }
      });

      if (res.success) {
        this.$Message.success(this.$t('xiu-gai-di-zhi-cheng-gong'));
        this.showModifyIpModal = false;
      }
    },
    async getDs(id) {
      const res = await this.$services.getDs({ data: { dataSourceId: id } });
      if (res.success) {
        return res;
      }
    },
    async getDsList() {
      const res = await this.$services.getDmDsList({ data: { useVisibility: true } });

      if (res.success) {
        this.datasourceList = res.data;
      }
    },
    handleQuery() {
      const {
        type,
        text
      } = this.queryForm;
      this.datasourceFilterInfo = {
        deployEnvType: type,
        query: text
      };
    },
    handleClickAddBtn() {
      this.$router.push({ name: 'System_DataSource_Add' });
    },
    async handleActions(action, row) {
      // console.log(action.key, row);
      // const res = await this.getDs(row.id);
      // if (res.success) {
      //   if (action.key === 'add_whitelist') {
      //     res.data.whiteListAddType = 'ADD_ALL';
      //   }
      //   this.selectedDatasource = res.data;
      // }
      this.selectedDatasource = row;
      switch (action.key) {
        case 'ticket':
          this.showTicketModal = true;
          break;
        case 'enableQuery':
          this.showEnableQueryModal = true;
          break;
        case 'disableQuery':
          Modal.confirm({
            title: this.$t('ting-yong-cha-xun-gong-neng-que-ren'),
            content: this.$t('que-ren-yao-ting-yong-gai-shu-ju-yuan-de-cha-xun-gong-neng-ma-ting-yong-hou-jiang-wu-fa-cha-xun-cao-zuo-huo-ti-jiao-gong-dan'),
            onOk: () => {
              this.handleDisableDsQuery(row);
            }
          });
          break;
        case 'modify_datasource':
          await this.listEnv();
          await this.$store.dispatch(ACTIONS_TYPE.GET_CLUSTER_LIST, this.selectedDatasource.deployEnvType);

          this.showModifyDatasourceModal = true;
          break;
        case 'add_whitelist':
          await this.queryWhiteList(row.id);
          await this.getWorkerList(row.bindClusterId);
          await this.getClusterDetail(row.bindClusterId);
          this.showEditWhiteIpModal = true;
          break;
        case 'modify_address':
          // this.selectedDatasource = { ...this.selectedDatasource };
          this.selectedDatasource.defaultHostType = this.selectedDatasource.defaultHost === this.selectedDatasource.privateHost ? 'private' : 'public';
          this.showModifyIpModal = true;
          break;
        case 'modify_datasource_account':
          this.showModifyDatasourceAccountModal = true;
          break;
        case 'delete_datasource':
          this.showDeleteDatasourceModal = true;
          break;
        case 'go_params':
          this.$router.push({ path: `/ccdatasource/params/${row.id}` });
          break;
        default:
          break;
      }
    },
    handleGoConsoleJob(row) {
      this.$router.push({ path: `/system/console_job/${row.consoleJobId}` });
    },
    handleClose() {
      this.showEnableQueryModal = false;
    }
  },
  created() {
    this.getDsList();
    this.$store.dispatch(ACTIONS_TYPE.GET_DEPLOY_ENV_LIST);
  }
};
</script>

<style lang="less" scoped>
.datasource {
  padding: 10px;
}

.datasource-detail {
  min-width: 480px;
  max-width: 480px;

  & > div {
    padding: 5px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #DADADA;
    }
  }

  section {
    display: flex;
    padding: 5px 0;
    font-weight: bold;

    &:not(:first-child) > div {
      width: 50%;
    }

    span {
      color: #888;
    }
  }
}

.edit-ip-modal {
  font-size: 12px;

  .address {
    display: flex;
    align-items: center;
    //justify-content: space-between;

    .title {
      min-width: 80px;
    }

    &:not(first-child) {
      margin-bottom: 12px;
    }
  }
}

.worker-detail {
  display: flex;
  align-items: center;
  height: 70px;
  border: 1px solid rgba(234, 234, 234, 1);
  border-bottom: none;
  background: rgba(250, 250, 250, 1);

  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(255, 193, 92, 1);
    box-shadow: 0 3px 12px 0 rgba(255, 192, 92, 1);
    text-align: center;
    line-height: 40px;
    margin-left: 20px;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-left: 17px;

    & > div {
      display: flex;
    }

    .first {
      display: flex;
      align-items: center;
      margin-bottom: 6px;

      & > div:first-child {
        font-size: 14px;
        font-weight: bold;
        margin-right: 10px;
      }
    }

    .second {
      & > div {
        border-radius: 14px;
        padding: 0 5px;
        background-color: rgba(224, 224, 224, 1);

        &:first-child {
          margin-right: 8px;
        }
      }
    }
  }
}

</style>
