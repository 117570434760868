<template>
  <Modal :value="visible" :title="$t('kai-qi-cha-xun-gong-neng')" :mask-closable="false" @on-cancel="_handleCloseModal" width="380"
         footer-hide>
    <Form :model="form">
      <FormItem :label="$t('bang-ding-ji-qun')" prop="clusterId">
        <Select v-model="form.clusterId" style="width:280px"
                filterable @on-change="handleChangeCluster">
          <Option v-for="(cluster) in clusterList" :value="cluster.id" :key="cluster.id"
                  :label="cluster.clusterDesc?cluster.clusterDesc:cluster.clusterName" :style="`${cluster.runningCount ? '' : 'cursor: not-allowed'}`">
            <p>{{ cluster.clusterName }}</p>
            <p style="color:#ccc;margin: 5px 0">
              {{ cluster.clusterDesc }}
              <span style="margin-left: 8px">{{ cluster.runningCount }}/{{
                  cluster.workerCount
                }}</span>
            </p>
          </Option>
        </Select>
      </FormItem>
      <FormItem label="" v-if="this.currentCluster.runningCount===0">
        <span>
          <span style="color: #FF6E0C">
            <i
              style="margin-left: 10px;margin-right: 8px"
              class="iconfont iconTIP"></i>{{ $t('gai-ji-qun-wu-cun-huo-ji-qi') }}
          </span>
          <a :href="`/#/dmmachine/list/${form.clusterId}`">{{ $t('guan-li-ji-qi') }}</a>
        </span>
      </FormItem>
      <FormItem :label="$t('wang-luo-lei-xing')" prop="host">
        <!--        <Select v-model="form.hostType" style="width:280px">-->
        <!--          <Option value="public">外网</Option>-->
        <!--          <Option value="private">内网</Option>-->
        <!--        </Select>-->
        <RadioGroup v-model="form.hostType" @on-change="handleChangeHostType">
          <Radio label="PRIVATE" v-if="datasource.privateHost || datasource.host">
            {{ $t('nei-wang') }}
          </Radio>
          <Radio label="PUBLIC" v-if="datasource.publicHost">
            {{ $t('wai-wang') }}
          </Radio>
        </RadioGroup>
      </FormItem>
<!--      <FormItem>-->
<!--        <Button :loading="loading" @click="testConnection" style="margin-right: 10px;">{{ $t('ce-shi-lian-jie') }}</Button>-->
<!--        <span v-if="hasTest">-->
<!--          <Icon :type="testSuccess ? 'ios-checkmark-circle' : 'ios-close-circle'"-->
<!--                :color="testSuccess ? 'green' : 'red'"/>-->
<!--          {{ testSuccess ? $t('ce-shi-lian-jie-cheng-gong') : $t('ce-shi-lian-jie-shi-bai') }}-->
<!--        </span>-->
<!--      </FormItem>-->
    </Form>
    <div class="footer">
      <a-button type="primary" style="width: 120px;margin-right: 16px;"
                @click="handleEnableQuery">{{ $t('que-ren') }}
      </a-button>
      <a-button style="width: 120px;" @click="handleCloseModal">
        {{ $t('qu-xiao') }}
      </a-button>
    </div>
  </Modal>
</template>

<script>
import { listClusterByCondition } from '@/services/cc/api/cluster';
import { testConnectionBeforeAdd, testConnection } from '@/services/cc/api/datasource';
import { isDb2, isHana } from '@/utils';
import DataSourceGroup from '@/views/dataSourceGroup';

export default {
  name: 'EnableQueryModal',
  props: {
    visible: Boolean,
    datasource: Object,
    handleCloseModal: Function,
    getDsList: Function,
    type: {
      default: 'addDataSource'
    }
  },
  data() {
    return {
      taskInfo: {},
      clusterList: [],
      currentCluster: {},
      form: {
        clusterId: '',
        hostType: 'PRIVATE'
      },
      hasTest: false,
      testSuccess: false,
      loading: false,
      useSSL: false
    };
  },
  methods: {
    handleChangeHostType() {
      this.hasTest = false;
      this.testSuccess = false;
      this.loading = false;
    },
    _handleCloseModal() {
      this.hasTest = false;
      this.testSuccess = false;
      this.loading = false;
      this.form = {
        clusterId: '',
        hostType: 'PRIVATE'
      };
      this.handleCloseModal();
    },
    separatePort(type) {
      return !['Kudu', 'Redis', 'ClickHouse', 'ElasticSearch', 'RabbitMQ', 'RocketMQ', 'Kafka', 'MongoDB', 'PolarDbX'].includes(type);
    },
    testConnection() {
      if (this.type === 'addDataSource') {
        this.testConnectionBeforeAdd();
      } else {
        this.loading = true;
        const instance = this.datasource;
        const data = `${instance.host}|${instance.id}|${instance.autoCreateAccount}
      |${instance.hasPassword}|${instance.privateHost}|${instance.publicHost}|${instance.instanceId}|${instance.securityType}`;
        this.taskInfo.clusterId = this.form.clusterId;
        this.taskInfo.sourceHostType = this.form.hostType;
        this.taskInfo.sourceType = instance.dataSourceType;
        this.taskInfo.sourceAccount = instance.accountName;
        this.taskInfo.sourceInstance = data.split('|')[0];
        this.taskInfo.sourceDataSourceId = data.split('|')[1];
        this.taskInfo.sourceAuto = data.split('|')[2];
        this.taskInfo.hasSourcePassword = JSON.parse(data.split('|')[3]);
        this.taskInfo.sourcePrivateHost = data.split('|')[4];
        this.taskInfo.sourceInstanceId = data.split('|')[6];
        this.taskInfo.sourceSecurityType = data.split('|')[7];
        if (this.taskInfo.sourceSecurityType === 'NONE') {
          this.taskInfo.sourceAccount = null;
          this.taskInfo.sourcePasswd = null;
        }
        if (data.split('|')[5] === 'null') {
          this.taskInfo.sourcePublicHost = '';
        } else {
          this.taskInfo.sourcePublicHost = data.split('|')[5];
        }
        if (this.taskInfo.sourceAuto === 'CREATED' || this.taskInfo.hasSourcePassword) {
          this.taskInfo.sourceAutoCreateAccount = true;
          this.taskInfo.sourceSpecifiedUserPassword = false;
        } else {
          this.taskInfo.sourceAutoCreateAccount = false;
          this.taskInfo.sourceSpecifiedUserPassword = true;
        }

        testConnection({
          host: this.form.hostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
          privateHost: this.taskInfo.sourcePrivateHost,
          publicHost: this.taskInfo.sourcePublicHost,
          hostType: this.taskInfo.sourceHostType,
          type: this.taskInfo.sourceType,
          userName: DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) > -1
          && this.taskInfo.sourceAccountRole ? `${this.taskInfo.sourceAccount} as SYSDBA` : this.taskInfo.sourceAccount,
          password: this.taskInfo.sourcePasswd,
          dataSourceId: this.taskInfo.sourceDataSourceId,
          autoCreateAccount: this.taskInfo.sourceAutoCreateAccount,
          hasPassword: this.taskInfo.hasSourcePassword,
          clusterId: this.taskInfo.clusterId,
          specifiedUserPassword: this.taskInfo.sourceSpecifiedUserPassword
        }).then((res) => {
          this.hasTest = true;
          if (res.data.code === '1') {
            this.testSuccess = true;
            this.msg = this.$t('ce-shi-lian-jie-cheng-gong');
          } else {
            this.testSuccess = false;
            this.msg = this.$t('ce-shi-lian-jie-shi-bai');
          }
        }).catch((e) => {
          console.log(e);
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    testConnectionBeforeAdd() {
      // parse useSSL from kvConfig
      this.extractUseSSL();
      const {
        asSysDba,
        host,
        port,
        publicHost,
        publicPort,
        account,
        password,
        type,
        securityType,
        connectType,
        dbName,
        noValidateDbName,
        connectTypeValue,
        driver,
        version
      } = this.datasource;
      const {
        hostType,
        clusterId
      } = this.form;
      const isSeparate = this.separatePort(type);
      this.loading = true;
      testConnectionBeforeAdd({
        dbName: (isDb2(type) || isHana(type)) ? dbName : noValidateDbName,
        clusterId,
        dsType: type,
        securityType,
        connectType,
        host: hostType === 'PUBLIC'
          ? isSeparate ? `${publicHost}:${publicPort}${type === 'Oracle' ? `:${connectTypeValue}` : ''}` : publicHost
          : isSeparate ? `${host}:${port}${type === 'Oracle' ? `:${connectTypeValue}` : ''}` : host,
        userName: `${account}${asSysDba ? ' as SYSDBA' : ''}`,
        password,
        driver,
        version,
        useSSL: this.useSSL
      })
        .then((res) => {
          this.hasTest = true;
          if (res.data.code === '1') {
            this.testSuccess = true;
            this.msg = this.$t('ce-shi-lian-jie-cheng-gong');
          } else {
            this.testSuccess = false;
            this.msg = this.$t('ce-shi-lian-jie-shi-bai');
          }
        }).finally(() => {
          this.loading = false;
        });
    },
    handleChangeCluster() {
      this.clusterList.forEach((cluster) => {
        if (cluster.id === this.form.clusterId) {
          this.currentCluster = cluster;
        }
      });
    },
    getCluster() {
      listClusterByCondition({})
        .then((res) => {
          if (res.data.code === '1') {
            this.clusterList = res.data.data;
            for (let i = 0; i < this.clusterList.length; i++) {
              if (this.clusterList[i].runningCount > 0) {
                this.form.clusterId = this.clusterList[i].id;
                return;
              }
            }
          }
        });
    },
    async getAllClusterList() {
      const res = await this.$services.getAllClusterList();
      if (res.code === '1') {
        this.clusterList = res.data;
        for (let i = 0; i < this.clusterList.length; i++) {
          if (this.clusterList[i].runningCount > 0) {
            this.form.clusterId = this.clusterList[i].id;
            return;
          }
        }
      }
    },
    getCurrentHostType() {
      const hostType = this.datasource.privateHost || this.datasource.host ? 'PRIVATE' : 'PUBLIC';
      this.form.hostType = hostType;
    },
    extractUseSSL() {
      if (this.datasource.dsKvConfigs === undefined || this.datasource.dsKvConfigs.length === 0) {
        this.useSSL = false;
        return;
      }
      this.datasource.dsKvConfigs.forEach((config) => {
        if (config.configName === 'useSSL') {
          if (config.currentCount === undefined) {
            this.useSSL = config.defaultValue;
            return;
          }
          this.useSSL = config.currentCount;
        }
      });
    },
    async handleEnableQuery() {
      const res = await this.$services.enableDsQuery({
        data: {
          dataSourceId: this.datasource.id,
          clusterId: this.form.clusterId,
          hostType: this.form.hostType
        }
      });
      if (res.success) {
        this.$message.success(this.$t('qi-yong-cheng-gong'));
        this.getDsList();
        this.handleCloseModal();
      }
      console.log('res', res);
    }
  },
  created() {
    // this.getCluster();
    // this.getAllClusterList();
  },
  watch: {
    visible(val) {
      if (val) {
        // this.getCluster();
        this.getAllClusterList();
        this.getCurrentHostType();
      }
    },
    datasource: {
      deep: true,
      handler(val) {
        const hostType = val.privateHost || val.host ? 'PRIVATE' : 'PUBLIC';
        this.form.hostType = hostType;
      }
    }
  }
};
</script>

<style scoped lang="less">
.footer {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
